.Char {
  height: 200px;
  width: 200px;
}

.Char-Deleted {
  background-color: #fcc;
  height: 100%;
}

.Char-Active {
  background-color: #ccf;
  height: 100%;
}