.ExternalEvents {
    position: fixed;
    z-index: 2;
    top: 20px;
    top: 100px;
    left: 20px;
    width: 200px;
    padding: 0 10px;
    border: 1px solid #ccc;
    background: #eee;
    height: 800px;
    overflow-y:scroll;
}

.ExternalEvents .fc-event {
    margin: 3px 0;
}

.ExternalEvents .fc-event-drag {
    cursor: move;
}

.ExternalEvents .fc-event-disabled {
    background-color: #f44;
}
