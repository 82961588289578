.NavBar {
    display: flex;
    align-items: center;
    left: 0;
    height: 2em;
    background-color: #eee;
    margin-bottom: 2em;
    padding-left: 5em;
    padding-right: 5em;
    box-shadow: 0 0 20px rgb(0 0 0 / 60%);
}

.NavBar a {
    color: #a0a0a0;
    font-weight: bold;
    text-decoration: none;
}

.NavBar-Row {
    display: block;
    width: 100%;
}

.NavBar-Left-Link {
    /* display: inline-block; */
    padding-right: 3em;
    float: left;
}

.NavBar-Left-Right {
    /* display: inline-block; */
    padding-left: 3em;
    float: right;
}