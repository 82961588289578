body {
    background-color: #fff;
}

.Login {
    text-align: center;
    position: relative;
    height: 100vh;
}

.Login-Title {
    display: block;
    font-size: 1.5em;
    font-weight: bold;
}

.Login-Box {
    position: absolute;
    box-shadow: 0 0 20px rgb(0 0 0 / 20%);
    border-radius: 10px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    padding: 20px
}

.Login-Table input[type=password] {
    border-radius: 10px;
    text-align: left;
    border-width: 0px;
    font-size: 15px
}

.Login-Table input[type=password]:focus-visible {
    outline: 0;
}

.Login-Table {
    border-collapse: separate;
    border-spacing: 0 0.7em;
}

.Login-Table .Login-Table-Input-Prepend {    
    border: 1px #ddd solid;
    background-color: #eee;
    margin: 0px;
    font-weight: 400;
    padding: 10px;
    width: 10em;    
    text-align: left;
}

.Login-Table .Login-Table-Input {
    border: 1px #ddd solid;
    padding-left: 1em;
}

.Login-Table input[type="submit"] {
    width: 100%;
    height: 100%;
    height: 30px;
    border: 0;
}

.Login-Table .Login-Submit-Ready {
    background-color: #007bff;
    color: #fff;
    font-weight: 700;
}

.Login-Table .Login-Submit-Loading {
    background-color: #ccc;
    color: #fff;
    font-weight: 700;
}